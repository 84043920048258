@keyframes expandSection {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    height: 28vh; /* Keep the height to maintain the background size */
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    height: 28vh;
  }
}

@keyframes fadeInContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 2560px) {
  .wishlist {
    width: 40vw;
    margin-top: -4vh;
  }
  .discord {
    width: 20vw;
    margin: 20px 0;
  }
  .join {
    width: 40vw;
    margin-top: -10vh;
  }

  .iframe-container {
    width: 60vh;
    height: 34vh;
    justify-content: center;
    overflow: hidden;
    transform-origin: center;
  }

  .iframe {
    overflow: clip;
    min-width: 100%;
    height: 100%;
  }

  .subscribe-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: 71vh;
    left: 0;
    width: 100vw;
    max-width: 100vw;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    color: #000;
    text-align: center;
    overflow: hidden;
    height: 0px;
    animation: fadeInContent 0.7s forwards ease-in-out;
    animation: expandSection 1s forwards ease-in-out;
    animation-delay: 0s;
    /* Resets padding for the start of the animation */
    padding-top: 0;
    padding-bottom: 0;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-content,
  .subscribe-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5vw; /* Adjust padding as necessary to avoid content overflow */
  }

  .subscribe-content {
    grid-column: 1 / 2;
    user-select: none;
    margin-left: 10vw;
    opacity: 0; /* Start with hidden content */
    animation: fadeInContent 2s forwards ease-in-out;
    animation-delay: 0s; /* Delay matches the duration of expandSection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-form {
    grid-column: 2 / 3;
    margin-left: -17vw;
    user-select: none;
    opacity: 0; /* Start with hidden content */
    animation: fadeInContent 2s forwards ease-in-out;
    animation-delay: 0s; /* Delay matches the duration of expandSection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .checkbox-section input[type="checkbox"] {
    margin-right: -10vw;
    margin-top: -4vh;
  }

  .checkbox-section {
    display: flex;
    align-items: center;
    margin: -2vh;
    margin-left: 0.5vw;
  }

  .subscribe-section h3 {
    width: 65vw;
    max-width: 65vh;
    margin-top: -1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-section input {
    background-color: #000; /* Sets input background to black */
    border: 1px solid #333232; /* Sets input border to black */
    color: #fff; /* Sets input text color to white for visibility */
    padding: 10px;
    margin-bottom: 4vh;
    font-size: 0.9vw; /* Increases font size for larger text */
    width: 15vw; /* Adjust width as needed */
    box-sizing: border-box;
  }

  .subscribe-section input:focus {
    outline: none; /* Removes the highlight/border when input is being used */
  }

  .subscribe-section button {
    background-color: rgb(82, 7, 7); /* Sets button background to red */
    border-color: rgb(212, 37, 6);
    border-width: 1px;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 7px 18px; /* Keeps padding as is */
    font-size: 1vw; /* Increases font size for larger text */
    font-weight: bold; /* Makes the button text bold */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    display: inline-block; /* Ensures button respects padding and size */
    box-sizing: border-box; /* Ensures padding and border are included in the button's total size */
  }

  .subscribe-section button:hover {
    background-color: darkred; /* Darkens the button on hover for a visual effect */
  }

  .consent-text {
    margin-left: 1vh;
    text-align: left;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .custom-checkbox {
    display: inline-block;
    width: 0.88vw; /* Adjusted to scale with viewport */
    height: 0.88vw; /* Adjusted to scale with viewport */
    border-radius: 50%; /* Keeps it a circle */
    margin-top: -5vh; /* You might need to adjust this based on layout */
    border: 0.2vw solid #dcb67a; /* Scales the border thickness with viewport */
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .custom-checkbox:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.5vw; /* Scaled down to fit inside the checkbox */
    height: 0.5vw; /* Scaled down to fit inside the checkbox */
    border-radius: 50%; /* Ensures it's a circle */
    background-color: #dcb67a; /* Fill color for the circle */
    transform: translate(-50%, -50%) scale(0); /* Centers the circle */
    transition: transform 0.2s ease-in-out;
  }

  .ensubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ensubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .enpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .essubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .essubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .espolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frsubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .desubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.5vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .desubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.1vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .depolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptsubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhsubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .jasubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .jasubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .japolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kosubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kosubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kopolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }
}

@media (max-width: 1920px) {
  .wishlist {
    width: 30vw;
    max-width: 600px;
    margin-top: -4vh;
  }
  .discord {
    width: 15vw;
    margin: 20px 0;
  }
  .join {
    width: 30vw;
    margin-top: -10vh;
  }

  .subscribe-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: 71vh;
    left: 0;
    width: 100vw;
    max-width: 100vw;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    color: #000;
    text-align: center;
    overflow: hidden;
    height: 0px;
    animation: fadeInContent 0.7s forwards ease-in-out;
    animation: expandSection 1s forwards ease-in-out;
    animation-delay: 0s;
    /* Resets padding for the start of the animation */
    padding-top: 0;
    padding-bottom: 0;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-content,
  .subscribe-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5vw; /* Adjust padding as necessary to avoid content overflow */
  }

  .subscribe-content {
    grid-column: 1 / 2;
    user-select: none;
    margin-left: 10vw;
    opacity: 0; /* Start with hidden content */
    animation: fadeInContent 2s forwards ease-in-out;
    animation-delay: 0s; /* Delay matches the duration of expandSection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-form {
    grid-column: 2 / 3;
    margin-left: -17vw;
    user-select: none;
    opacity: 0; /* Start with hidden content */
    animation: fadeInContent 2s forwards ease-in-out;
    animation-delay: 0s; /* Delay matches the duration of expandSection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .checkbox-section input[type="checkbox"] {
    margin-right: -10vw;
    margin-top: -4vh;
  }

  .checkbox-section {
    display: flex;
    align-items: center;
    margin: -2vh;
    margin-left: 0.5vw;
  }

  .subscribe-section h3 {
    width: 65vw;
    max-width: 65vh;
    margin-top: -1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-section input {
    background-color: #000; /* Sets input background to black */
    border: 1px solid #333232; /* Sets input border to black */
    color: #fff; /* Sets input text color to white for visibility */
    padding: 10px;
    margin-bottom: 4vh;
    font-size: 0.9vw; /* Increases font size for larger text */
    width: 15vw; /* Adjust width as needed */
    box-sizing: border-box;
  }

  .subscribe-section input:focus {
    outline: none; /* Removes the highlight/border when input is being used */
  }

  .subscribe-section button {
    background-color: rgb(82, 7, 7); /* Sets button background to red */
    border-color: rgb(212, 37, 6);
    border-width: 1px;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 7px 18px; /* Keeps padding as is */
    font-size: 1vw; /* Increases font size for larger text */
    font-weight: bold; /* Makes the button text bold */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    display: inline-block; /* Ensures button respects padding and size */
    box-sizing: border-box; /* Ensures padding and border are included in the button's total size */
  }

  .subscribe-section button:hover {
    background-color: darkred; /* Darkens the button on hover for a visual effect */
  }

  .consent-text {
    margin-left: 1vh;
    text-align: left;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .custom-checkbox {
    display: inline-block;
    width: 12px; /* Adjusted to scale with viewport */
    height: 12px; /* Adjusted to scale with viewport */
    border-radius: 50%; /* Keeps it a circle */
    margin-top: -8vh; /* You might need to adjust this based on layout */
    border: 0.2vw solid #dcb67a; /* Scales the border thickness with viewport */
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .custom-checkbox:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px; /* Diameter of the inner circle */
    height: 8px; /* Diameter of the inner circle */
    border-radius: 50%; /* Makes it a circle */
    background-color: #dcb67a; /* Circle fill color */
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.2s ease-in-out;
  }

  input[type="checkbox"]:checked + .custom-checkbox:after {
    transform: translate(-50%, -50%) scale(1); /* Expands the inner circle when checked */
  }

  .ensubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ensubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .enpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .essubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .essubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .espolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frsubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .desubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.5vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .desubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.1vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .depolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptsubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhsubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .jasubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .jasubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .japolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kosubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kosubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kopolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }
}

@media (max-width: 1440px) {
  .wishlist {
    width: 40vw;
    margin-top: -4vh;
  }
  .discord {
    width: 20vw;
    margin: 20px 0;
  }
  .join {
    width: 40vw;
    margin-top: -10vh;
  }

  .subscribe-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: 71vh;
    left: 0;
    width: 100vw;
    max-width: 100vw;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    color: #000;
    text-align: center;
    overflow: hidden;
    height: 0px;
    animation: fadeInContent 0.7s forwards ease-in-out;
    animation: expandSection 1s forwards ease-in-out;
    animation-delay: 0s;
    /* Resets padding for the start of the animation */
    padding-top: 0;
    padding-bottom: 0;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-content,
  .subscribe-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5vw; /* Adjust padding as necessary to avoid content overflow */
  }

  .subscribe-content {
    grid-column: 1 / 2;
    user-select: none;
    margin-left: 10vw;
    opacity: 0; /* Start with hidden content */
    animation: fadeInContent 2s forwards ease-in-out;
    animation-delay: 0s; /* Delay matches the duration of expandSection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-form {
    grid-column: 2 / 3;
    margin-left: -17vw;
    user-select: none;
    opacity: 0; /* Start with hidden content */
    animation: fadeInContent 2s forwards ease-in-out;
    animation-delay: 0s; /* Delay matches the duration of expandSection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .checkbox-section input[type="checkbox"] {
    margin-right: -10vw;
    margin-top: -4vh;
  }

  .checkbox-section {
    display: flex;
    align-items: center;
    margin: -2vh;
    margin-left: 0.5vw;
  }

  .subscribe-section h3 {
    width: 65vw;
    max-width: 65vh;
    margin-top: -1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-section input {
    background-color: #000; /* Sets input background to black */
    border: 1px solid #333232; /* Sets input border to black */
    color: #fff; /* Sets input text color to white for visibility */
    padding: 10px;
    margin-bottom: 4vh;
    font-size: 0.9vw; /* Increases font size for larger text */
    width: 15vw; /* Adjust width as needed */
    box-sizing: border-box;
  }

  .subscribe-section input:focus {
    outline: none; /* Removes the highlight/border when input is being used */
  }

  .subscribe-section button {
    background-color: rgb(82, 7, 7); /* Sets button background to red */
    border-color: rgb(212, 37, 6);
    border-width: 1px;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 7px 18px; /* Keeps padding as is */
    font-size: 1vw; /* Increases font size for larger text */
    font-weight: bold; /* Makes the button text bold */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    display: inline-block; /* Ensures button respects padding and size */
    box-sizing: border-box; /* Ensures padding and border are included in the button's total size */
  }

  .subscribe-section button:hover {
    background-color: darkred; /* Darkens the button on hover for a visual effect */
  }

  .consent-text {
    margin-left: 1vh;
    text-align: left;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .custom-checkbox {
    display: inline-block;
    width: 0.88vw; /* Adjusted to scale with viewport */
    height: 0.88vw; /* Adjusted to scale with viewport */
    border-radius: 50%; /* Keeps it a circle */
    margin-top: -4vh; /* You might need to adjust this based on layout */
    border: 0.2vw solid #dcb67a; /* Scales the border thickness with viewport */
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .custom-checkbox:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.5vw; /* Scaled down to fit inside the checkbox */
    height: 0.5vw; /* Scaled down to fit inside the checkbox */
    border-radius: 50%; /* Ensures it's a circle */
    background-color: #dcb67a; /* Fill color for the circle */
    transform: translate(-50%, -50%) scale(0); /* Centers the circle */
    transition: transform 0.2s ease-in-out;
  }

  .ensubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ensubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .enpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .essubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .essubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .espolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frsubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .desubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.5vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .desubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.1vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .depolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptsubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhsubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .jasubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .jasubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .japolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kosubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kosubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kopolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }
}

@media (max-width: 1360px) {
  .wishlist {
    width: 35vw;
    margin-top: -4vh;
  }
  .discord {
    width: 18vw;
    margin: 20px 0;
  }
  .join {
    width: 35vw;
    margin-top: -10vh;
  }

  .subscribe-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: 71vh;
    left: 0;
    width: 100vw;
    max-width: 100vw;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    color: #000;
    text-align: center;
    overflow: hidden;
    height: 0px;
    animation: fadeInContent 0.7s forwards ease-in-out;
    animation: expandSection 1s forwards ease-in-out;
    animation-delay: 0s;
    /* Resets padding for the start of the animation */
    padding-top: 0;
    padding-bottom: 0;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-content,
  .subscribe-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5vw; /* Adjust padding as necessary to avoid content overflow */
  }

  .subscribe-content {
    grid-column: 1 / 2;
    user-select: none;
    margin-left: 10vw;
    opacity: 0; /* Start with hidden content */
    animation: fadeInContent 2s forwards ease-in-out;
    animation-delay: 0s; /* Delay matches the duration of expandSection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-form {
    grid-column: 2 / 3;
    margin-left: -17vw;
    user-select: none;
    opacity: 0; /* Start with hidden content */
    animation: fadeInContent 2s forwards ease-in-out;
    animation-delay: 0s; /* Delay matches the duration of expandSection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .checkbox-section input[type="checkbox"] {
    margin-right: -10vw;
    margin-top: -4vh;
  }

  .checkbox-section {
    display: flex;
    align-items: center;
    margin: -2vh;
    margin-left: 0.5vw;
  }

  .subscribe-section h3 {
    width: 65vw;
    max-width: 65vh;
    margin-top: -1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-section input {
    background-color: #000; /* Sets input background to black */
    border: 1px solid #333232; /* Sets input border to black */
    color: #fff; /* Sets input text color to white for visibility */
    padding: 10px;
    margin-bottom: 4vh;
    font-size: 0.9vw; /* Increases font size for larger text */
    width: 15vw; /* Adjust width as needed */
    box-sizing: border-box;
  }

  .subscribe-section input:focus {
    outline: none; /* Removes the highlight/border when input is being used */
  }

  .subscribe-section button {
    background-color: rgb(82, 7, 7); /* Sets button background to red */
    border-color: rgb(212, 37, 6);
    border-width: 1px;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 7px 18px; /* Keeps padding as is */
    font-size: 1vw; /* Increases font size for larger text */
    font-weight: bold; /* Makes the button text bold */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    display: inline-block; /* Ensures button respects padding and size */
    box-sizing: border-box; /* Ensures padding and border are included in the button's total size */
  }

  .subscribe-section button:hover {
    background-color: darkred; /* Darkens the button on hover for a visual effect */
  }

  .consent-text {
    margin-left: 1vh;
    text-align: left;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .custom-checkbox {
    display: inline-block;
    width: 0.88vw; /* Adjusted to scale with viewport */
    height: 0.88vw; /* Adjusted to scale with viewport */
    border-radius: 50%; /* Keeps it a circle */
    margin-top: -8vh; /* You might need to adjust this based on layout */
    border: 0.2vw solid #dcb67a; /* Scales the border thickness with viewport */
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .custom-checkbox:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.5vw; /* Scaled down to fit inside the checkbox */
    height: 0.5vw; /* Scaled down to fit inside the checkbox */
    border-radius: 50%; /* Ensures it's a circle */
    background-color: #dcb67a; /* Fill color for the circle */
    transform: translate(-50%, -50%) scale(0); /* Centers the circle */
    transition: transform 0.2s ease-in-out;
  }

  .ensubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ensubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .enpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .essubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .essubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .espolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frsubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .desubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.5vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .desubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.1vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .depolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptsubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhsubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhpolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .jasubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .jasubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .japolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kosubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kosubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kopolicy {
    width: 54vw;
    max-width: 54vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }
}

@media (max-width: 1024px) {
  .wishlist {
    width: 45vw;
    margin-top: -4vh;
  }
  .discord {
    width: 25vw;
    margin: 20px 0;
  }
  .join {
    width: 45vw;
    margin-top: -10vh;
  }

  .subscribe-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: 71vh;
    left: 0;
    width: 100vw;
    max-width: 100vw;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    color: #000;
    text-align: center;
    overflow: hidden;
    height: 0px;
    animation: fadeInContent 0.7s forwards ease-in-out;
    animation: expandSection 1s forwards ease-in-out;
    animation-delay: 0s;
    /* Resets padding for the start of the animation */
    padding-top: 0;
    padding-bottom: 0;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-content,
  .subscribe-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5vw; /* Adjust padding as necessary to avoid content overflow */
  }

  .subscribe-content {
    grid-column: 1 / 2;
    user-select: none;
    margin-left: 10vw;
    opacity: 0; /* Start with hidden content */
    animation: fadeInContent 2s forwards ease-in-out;
    animation-delay: 0s; /* Delay matches the duration of expandSection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-form {
    grid-column: 2 / 3;
    margin-left: -17vw;
    user-select: none;
    opacity: 0; /* Start with hidden content */
    animation: fadeInContent 2s forwards ease-in-out;
    animation-delay: 0s; /* Delay matches the duration of expandSection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .checkbox-section input[type="checkbox"] {
    margin-right: -10vw;
    margin-top: -4vh;
  }

  .checkbox-section {
    display: flex;
    align-items: center;
    margin: -2vh;
    margin-left: 0.5vw;
  }

  .subscribe-section h3 {
    width: 65vw;
    max-width: 65vh;
    margin-top: -1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .subscribe-section input {
    background-color: #000; /* Sets input background to black */
    border: 1px solid #333232; /* Sets input border to black */
    color: #fff; /* Sets input text color to white for visibility */
    padding: 10px;
    margin-bottom: 4vh;
    font-size: 0.9vw; /* Increases font size for larger text */
    width: 15vw; /* Adjust width as needed */
    box-sizing: border-box;
  }

  .subscribe-section input:focus {
    outline: none; /* Removes the highlight/border when input is being used */
  }

  .subscribe-section button {
    background-color: rgb(82, 7, 7); /* Sets button background to red */
    border-color: rgb(212, 37, 6);
    border-width: 1px;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 7px 18px; /* Keeps padding as is */
    font-size: 1vw; /* Increases font size for larger text */
    font-weight: bold; /* Makes the button text bold */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    display: inline-block; /* Ensures button respects padding and size */
    box-sizing: border-box; /* Ensures padding and border are included in the button's total size */
  }

  .subscribe-section button:hover {
    background-color: darkred; /* Darkens the button on hover for a visual effect */
  }

  .consent-text {
    margin-left: 1vh;
    text-align: left;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .custom-checkbox {
    display: inline-block;
    width: 0.88vw; /* Adjusted to scale with viewport */
    height: 0.88vw; /* Adjusted to scale with viewport */
    border-radius: 50%; /* Keeps it a circle */
    margin-top: -3vh; /* You might need to adjust this based on layout */
    border: 0.2vw solid #dcb67a; /* Scales the border thickness with viewport */
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .custom-checkbox:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.5vw; /* Scaled down to fit inside the checkbox */
    height: 0.5vw; /* Scaled down to fit inside the checkbox */
    border-radius: 50%; /* Ensures it's a circle */
    background-color: #dcb67a; /* Fill color for the circle */
    transform: translate(-50%, -50%) scale(0); /* Centers the circle */
    transition: transform 0.2s ease-in-out;
  }

  .ensubscribe-header {
    width: 70vw;
    max-width: 70vh;
    margin-left: -10vw;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ensubscribe-body {
    width: 50vw;
    max-width: 50vh;
    margin-top: 1vh;
    margin-left: -10vw;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .enpolicy {
    width: 40vw;
    max-width: 40vh;
    overflow-wrap: break-word;
    color: rgb(182, 182, 182);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .essubscribe-header {
    width: 70vw;
    max-width: 70vh;

    margin-left: -10vw;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .essubscribe-body {
    width: 45vw;
    max-width: 45vh;

    margin-left: -10vw;
    margin-top: 1vh;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .espolicy {
    width: 35vw;
    max-width: 35vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    margin-left: -10vw;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frsubscribe-body {
    width: 45vw;
    max-width: 45vh;
    margin-top: 1vh;
    margin-left: -10vw;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .frpolicy {
    width: 35vw;
    max-width: 35vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .desubscribe-header {
    width: 70vw;
    max-width: 70vh;
    margin-left: -10vw;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.5vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .desubscribe-body {
    width: 45vw;
    max-width: 45vh;
    margin-top: 1vh;
    margin-left: -10vw;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.1vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .depolicy {
    width: 35vw;
    max-width: 35vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    margin-left: -10vw;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptsubscribe-body {
    width: 45vw;
    max-width: 45vh;
    margin-top: 1vh;

    margin-left: -10vw;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .ptpolicy {
    width: 35vw;
    max-width: 35vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhsubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    margin-left: -10vw;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhsubscribe-body {
    width: 65vw;
    max-width: 65vh;
    margin-top: 1vh;
    margin-left: -10vw;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .zhpolicy {
    width: 35vw;
    max-width: 35vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .jasubscribe-header {
    width: 70vw;
    max-width: 70vh;
    margin-left: -10vw;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .jasubscribe-body {
    width: 45vw;
    max-width: 45vh;
    margin-top: 1vh;
    margin-left: -10vw;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .japolicy {
    width: 35vw;
    max-width: 35vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kosubscribe-header {
    width: 70vw;
    max-width: 70vh;
    color: #dcb67a;
    margin-left: -10vw;
    font-family: "Cinzel", serif;
    font-size: 1.8vw; /* Adjusted for scaling */
    font-weight: bold;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kosubscribe-body {
    width: 45vw;
    max-width: 45vh;
    margin-top: 1vh;
    margin-left: -10vw;
    color: rgb(138, 138, 138);
    font-family: "Cinzel", serif;
    font-size: 1.3vw; /* Adjusted for scaling */
    font-weight: bold;
    overflow-wrap: break-word;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .kopolicy {
    width: 35vw;
    max-width: 35vh;
    overflow-wrap: break-word;
    color: rgb(138, 138, 138);
    font-size: 1vw; /* Adjusted for scaling */
    cursor: pointer;
    font-family: "Cinzel", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }
}
