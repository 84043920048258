@media (max-width: 2560px) {
  .App {
    text-align: center;
  }

  .fullpage-background {
    height: 100vh; /* Make the div take up the full viewport height */
    width: 100vw; /* Make the div take up the full viewport width */
    background-size: cover; /* Cover the entire div without stretching the image */
    background-position: center; /* Center the background image */
    display: flex;
    align-items: center; /* Aligns child content vertically */
    justify-content: center; /* Aligns child content horizontally */
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  html,
  body {
    overflow: hidden; /* Prevent scrolling and hide scrollbar */
    height: 100%; /* Full height */
    margin: 0; /* Remove default margin */
    background-color: #000;
  }

  /* Transition Group Styles */
  .transition-group {
    position: relative;
  }

  .transition-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  /* Transition Styles */
  .fade-enter,
  .fade-exit-active {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .fade-enter-active,
  .fade-exit {
    position: relative;
  }

  .fade-enter,
  .fade-exit {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-exit-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }

  /* Additional styles for blurring effect, if needed */
  .blur-enter {
    filter: blur(5px);
    opacity: 0;
    transition: filter 0.5s ease-out, opacity 0.5s ease-out;
  }

  .blur-enter-active {
    filter: blur(0);
    opacity: 1;
  }

  .section-transition {
    transition: opacity 2s ease-in-out, filter 1.5s ease-in-out;
    width: 100%;
    height: 100vh; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; /* Use absolute positioning to layer sections */
    top: 0;
    left: 0;
  }

  .hidden {
    opacity: 0;
    filter: blur(5px);
  }

  .visible {
    opacity: 1;
    filter: blur(0);
  }

  .section-container > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 2s ease-in-out, filter 2s ease-in-out;
    opacity: 0; /* Start sections as invisible */
    filter: blur(10px);
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .section-container > div.active {
    opacity: 1;
    filter: blur(0);
    z-index: 1; /* Ensure the active section is always on top */
  }

  .video-container {
    pointer-events: auto; /* Ensure video can be interacted with */
  }

  .glow-on-hoverL {
    animation: moveLeftRight 2s ease-in-out infinite;
    cursor: pointer;
  }
  .glow-on-hoverR {
    animation: moveRightLeft 2s ease-in-out infinite;
    cursor: pointer;
  }

  @keyframes moveLeftRight {
    0% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(-5px);
    }
  }
  @keyframes moveRightLeft {
    0% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(5px);
    }
  }

  .patreon-button-container {
    display: inline-block; /* or 'block', depending on your layout */
    width: 520px; /* Example width */
    height: 50px; /* Example height */
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .patreon-button {
    opacity: 90%;
    transition: opacity 0.3s ease;
    background-size: contain;
    text-decoration: none;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    scale: 1.8;
  }
  .patreon-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 16vw;
    height: 7vh;
    object-fit: cover;
  }

  .patreon-button:hover {
    opacity: 100%;
  }

  .sh1 {
    width: 100vw;
    max-width: 80vh;
    overflow-wrap: break-word;
    color: rgb(190, 190, 190);
    font-size: 1.2vw;
    font-family: "Lora", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    margin-left: 0vw;
  }

  .sh2 {
    width: 100vw;
    max-width: 70vh;
    overflow-wrap: break-word;
    color: rgb(190, 190, 190);
    font-size: 1.2vw;
    font-family: "Lora", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .shi1 {
    margin-left: 0vw;
    width: 45vw;
    margin-top: 50px;
  }

  .subback {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 1800px;
    width: 2100px;
    z-index: 116;
    margin-top: -10vh;
  }

  .steamH {
    width: 800px;
  }
}

@media (max-width: 1920px) {
  .App {
    text-align: center;
  }

  .fullpage-background {
    height: 100vh; /* Make the div take up the full viewport height */
    width: 100vw; /* Make the div take up the full viewport width */
    background-size: cover; /* Cover the entire div without stretching the image */
    background-position: center; /* Center the background image */
    display: flex;
    align-items: center; /* Aligns child content vertically */
    justify-content: center; /* Aligns child content horizontally */
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  html,
  body {
    overflow: hidden; /* Prevent scrolling and hide scrollbar */
    height: 100%; /* Full height */
    margin: 0; /* Remove default margin */
    background-color: #000;
  }

  /* Transition Group Styles */
  .transition-group {
    position: relative;
  }

  .transition-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  /* Transition Styles */
  .fade-enter,
  .fade-exit-active {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .fade-enter-active,
  .fade-exit {
    position: relative;
  }

  .fade-enter,
  .fade-exit {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-exit-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }

  /* Additional styles for blurring effect, if needed */
  .blur-enter {
    filter: blur(5px);
    opacity: 0;
    transition: filter 0.5s ease-out, opacity 0.5s ease-out;
  }

  .blur-enter-active {
    filter: blur(0);
    opacity: 1;
  }

  .section-transition {
    transition: opacity 2s ease-in-out, filter 1.5s ease-in-out;
    width: 100%;
    height: 100vh; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; /* Use absolute positioning to layer sections */
    top: 0;
    left: 0;
  }

  .hidden {
    opacity: 0;
    filter: blur(5px);
  }

  .visible {
    opacity: 1;
    filter: blur(0);
  }

  .section-container > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 2s ease-in-out, filter 2s ease-in-out;
    opacity: 0; /* Start sections as invisible */
    filter: blur(10px);
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .section-container > div.active {
    opacity: 1;
    filter: blur(0);
    z-index: 1; /* Ensure the active section is always on top */
  }

  .video-container {
    pointer-events: auto; /* Ensure video can be interacted with */
  }

  .glow-on-hoverL {
    animation: moveLeftRight 2s ease-in-out infinite;
    cursor: pointer;
  }
  .glow-on-hoverR {
    animation: moveRightLeft 2s ease-in-out infinite;
    cursor: pointer;
  }

  @keyframes moveLeftRight {
    0% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(-5px);
    }
  }
  @keyframes moveRightLeft {
    0% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(5px);
    }
  }

  .patreon-button-container {
    display: inline-block; /* or 'block', depending on your layout */
    width: 520px; /* Example width */
    height: 50px; /* Example height */
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .patreon-button {
    opacity: 90%;
    transition: opacity 0.3s ease;
    background-size: cover;
    text-decoration: none;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    scale: 1.2;
  }
  .patreon-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 18vw;
    height: 7vh;
    object-fit: cover;
  }

  .patreon-button:hover {
    opacity: 100%;
  }

  .sh1 {
    width: 100vw;
    max-width: 100vw;
    overflow-wrap: break-word;
    color: rgb(190, 190, 190);
    font-size: 1.2vw;
    font-family: "Lora", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    margin-left: 0px;
    text-align: "left";
  }

  .sh2 {
    width: 100vw;
    overflow-wrap: break-word;
    color: rgb(190, 190, 190);
    font-size: 1.1vw;
    font-family: "Lora", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    text-align: "left";
  }

  .shi1 {
    width: 800px;
    margin-top: -50px;
    margin-left: 0px;
  }

  .subback {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 2400px;
    width: 1600px;
    z-index: 116;
    margin-top: 50px;
  }

  .steamH {
    width: 600px;
  }
}

@media (max-width: 1440px) {
  .App {
    text-align: center;
  }

  .fullpage-background {
    height: 100vh; /* Make the div take up the full viewport height */
    width: 100vw; /* Make the div take up the full viewport width */
    background-size: cover; /* Cover the entire div without stretching the image */
    background-position: center; /* Center the background image */
    display: flex;
    align-items: center; /* Aligns child content vertically */
    justify-content: center; /* Aligns child content horizontally */
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  html,
  body {
    overflow: hidden; /* Prevent scrolling and hide scrollbar */
    height: 100%; /* Full height */
    margin: 0; /* Remove default margin */
    background-color: #000;
  }

  /* Transition Group Styles */
  .transition-group {
    position: relative;
  }

  .transition-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  /* Transition Styles */
  .fade-enter,
  .fade-exit-active {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .fade-enter-active,
  .fade-exit {
    position: relative;
  }

  .fade-enter,
  .fade-exit {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-exit-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }

  /* Additional styles for blurring effect, if needed */
  .blur-enter {
    filter: blur(5px);
    opacity: 0;
    transition: filter 0.5s ease-out, opacity 0.5s ease-out;
  }

  .blur-enter-active {
    filter: blur(0);
    opacity: 1;
  }

  .section-transition {
    transition: opacity 2s ease-in-out, filter 1.5s ease-in-out;
    width: 100%;
    height: 100vh; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; /* Use absolute positioning to layer sections */
    top: 0;
    left: 0;
  }

  .hidden {
    opacity: 0;
    filter: blur(5px);
  }

  .visible {
    opacity: 1;
    filter: blur(0);
  }

  .section-container > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 2s ease-in-out, filter 2s ease-in-out;
    opacity: 0; /* Start sections as invisible */
    filter: blur(10px);
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .section-container > div.active {
    opacity: 1;
    filter: blur(0);
    z-index: 1; /* Ensure the active section is always on top */
  }

  .video-container {
    pointer-events: auto; /* Ensure video can be interacted with */
  }

  .glow-on-hoverL {
    animation: moveLeftRight 2s ease-in-out infinite;
    cursor: pointer;
  }
  .glow-on-hoverR {
    animation: moveRightLeft 2s ease-in-out infinite;
    cursor: pointer;
  }

  @keyframes moveLeftRight {
    0% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(-5px);
    }
  }
  @keyframes moveRightLeft {
    0% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(5px);
    }
  }

  .patreon-button-container {
    display: inline-block; /* or 'block', depending on your layout */
    width: 220px; /* Example width */
    height: 50px; /* Example height */
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .patreon-button {
    opacity: 90%;
    transition: opacity 0.3s ease;
    background-size: cover;
    text-decoration: none;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .patreon-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 18vw;
    height: 7vh;
    object-fit: cover;
  }

  .patreon-button:hover {
    opacity: 100%;
  }

  .sh1 {
    width: 100vw;
    overflow-wrap: break-word;
    color: rgb(190, 190, 190);
    font-size: 1.5vw;
    font-family: "Lora", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    margin-left: 0vw;
    margin-top: 10px;
    text-align: "left";
  }

  .sh2 {
    width: 100vw;
    overflow-wrap: break-word;
    color: rgb(190, 190, 190);
    font-size: 1.3vw;
    font-family: "Lora", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    text-align: "left";
  }

  .shi1 {
    width: 45vw;
    margin-top: 0px;
  }

  .subback {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 93vh;
    width: 93vw;
    z-index: 116;
    margin-top: -10vh;
  }

  .steamH {
    width: 35vw;
  }
}

@media (max-width: 1360px) {
  .App {
    text-align: center;
  }

  .fullpage-background {
    height: 100vh; /* Make the div take up the full viewport height */
    width: 100vw; /* Make the div take up the full viewport width */
    background-size: cover; /* Cover the entire div without stretching the image */
    background-position: center; /* Center the background image */
    display: flex;
    align-items: center; /* Aligns child content vertically */
    justify-content: center; /* Aligns child content horizontally */
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  html,
  body {
    overflow: hidden; /* Prevent scrolling and hide scrollbar */
    height: 100%; /* Full height */
    margin: 0; /* Remove default margin */
    background-color: #000;
  }

  /* Transition Group Styles */
  .transition-group {
    position: relative;
  }

  .transition-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  /* Transition Styles */
  .fade-enter,
  .fade-exit-active {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .fade-enter-active,
  .fade-exit {
    position: relative;
  }

  .fade-enter,
  .fade-exit {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-exit-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }

  /* Additional styles for blurring effect, if needed */
  .blur-enter {
    filter: blur(5px);
    opacity: 0;
    transition: filter 0.5s ease-out, opacity 0.5s ease-out;
  }

  .blur-enter-active {
    filter: blur(0);
    opacity: 1;
  }

  .section-transition {
    transition: opacity 2s ease-in-out, filter 1.5s ease-in-out;
    width: 100%;
    height: 100vh; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; /* Use absolute positioning to layer sections */
    top: 0;
    left: 0;
  }

  .hidden {
    opacity: 0;
    filter: blur(5px);
  }

  .visible {
    opacity: 1;
    filter: blur(0);
  }

  .section-container > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 2s ease-in-out, filter 2s ease-in-out;
    opacity: 0; /* Start sections as invisible */
    filter: blur(10px);
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .section-container > div.active {
    opacity: 1;
    filter: blur(0);
    z-index: 1; /* Ensure the active section is always on top */
  }

  .video-container {
    pointer-events: auto; /* Ensure video can be interacted with */
  }

  .glow-on-hoverL {
    animation: moveLeftRight 2s ease-in-out infinite;
    cursor: pointer;
  }
  .glow-on-hoverR {
    animation: moveRightLeft 2s ease-in-out infinite;
    cursor: pointer;
  }

  @keyframes moveLeftRight {
    0% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(-5px);
    }
  }
  @keyframes moveRightLeft {
    0% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(5px);
    }
  }

  .patreon-button-container {
    display: inline-block; /* or 'block', depending on your layout */
    width: 520px; /* Example width */
    height: 50px; /* Example height */
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .patreon-button {
    opacity: 90%;
    transition: opacity 0.3s ease;
    background-size: contain;
    text-decoration: none;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    scale: 1;
  }
  .patreon-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 16vw;
    height: 8vh;
    object-fit: cover;
  }

  .patreon-button:hover {
    opacity: 100%;
  }

  .sh1 {
    width: 100vw;
    overflow-wrap: break-word;
    color: rgb(190, 190, 190);
    font-size: 1.3vw;
    font-family: "Lora", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    margin-left: 0vw;
    margin-top: 0;
    text-align: "left";
  }

  .sh2 {
    width: 35vw;
    overflow-wrap: break-word;
    color: rgb(190, 190, 190);
    font-size: 1.2vw;
    font-family: "Lora", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    margin-top: -1vh;
  }

  .shi1 {
    width: 45vw;
    margin-top: -4vh;
    margin-left: 0px;
  }

  .subback {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 82vh;
    width: 82vw;
    z-index: 116;
    margin-top: 0vh;
  }

  .steamH {
    width: 30vw;
  }
}

@media (max-width: 1024px) {
  .App {
    text-align: center;
  }

  .fullpage-background {
    height: 100vh; /* Make the div take up the full viewport height */
    width: 100vw; /* Make the div take up the full viewport width */
    background-size: cover; /* Cover the entire div without stretching the image */
    background-position: center; /* Center the background image */
    display: flex;
    align-items: center; /* Aligns child content vertically */
    justify-content: center; /* Aligns child content horizontally */
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  html,
  body {
    overflow: hidden; /* Prevent scrolling and hide scrollbar */
    height: 100%; /* Full height */
    margin: 0; /* Remove default margin */
    background-color: #000;
  }

  /* Transition Group Styles */
  .transition-group {
    position: relative;
  }

  .transition-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  /* Transition Styles */
  .fade-enter,
  .fade-exit-active {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .fade-enter-active,
  .fade-exit {
    position: relative;
  }

  .fade-enter,
  .fade-exit {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-exit-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }

  /* Additional styles for blurring effect, if needed */
  .blur-enter {
    filter: blur(5px);
    opacity: 0;
    transition: filter 0.5s ease-out, opacity 0.5s ease-out;
  }

  .blur-enter-active {
    filter: blur(0);
    opacity: 1;
  }

  .section-transition {
    transition: opacity 2s ease-in-out, filter 1.5s ease-in-out;
    width: 100%;
    height: 100vh; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; /* Use absolute positioning to layer sections */
    top: 0;
    left: 0;
  }

  .hidden {
    opacity: 0;
    filter: blur(5px);
  }

  .visible {
    opacity: 1;
    filter: blur(0);
  }

  .section-container > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 2s ease-in-out, filter 2s ease-in-out;
    opacity: 0; /* Start sections as invisible */
    filter: blur(10px);
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .section-container > div.active {
    opacity: 1;
    filter: blur(0);
    z-index: 1; /* Ensure the active section is always on top */
  }

  .video-container {
    pointer-events: auto; /* Ensure video can be interacted with */
  }

  .glow-on-hoverL {
    animation: moveLeftRight 2s ease-in-out infinite;
    cursor: pointer;
  }
  .glow-on-hoverR {
    animation: moveRightLeft 2s ease-in-out infinite;
    cursor: pointer;
  }

  @keyframes moveLeftRight {
    0% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(-5px);
    }
  }
  @keyframes moveRightLeft {
    0% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(5px);
    }
  }

  .patreon-button-container {
    display: inline-block; /* or 'block', depending on your layout */
    width: 1020px; /* Example width */
    height: 50px; /* Example height */
    position: relative;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .patreon-button {
    opacity: 90%;
    transition: opacity 0.3s ease;
    background-size: contain;
    text-decoration: none;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .patreon-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 20.5vw;
    height: 6vh;
    object-fit: cover;
  }

  .patreon-button:hover {
    opacity: 100%;
  }

  .sh1 {
    width: 100vw;
    overflow-wrap: break-word;
    color: rgb(190, 190, 190);
    font-size: 1.3vw;
    font-family: "Lora", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    margin-left: 0vw;
    text-align: "left";
  }

  .sh2 {
    width: 50vw;
    overflow-wrap: break-word;
    color: rgb(190, 190, 190);
    font-size: 1.3vw;
    font-family: "Lora", serif;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    margin-left: 0vw;
    text-align: "left";
    margin-top: 0vh;
  }

  .shi1 {
    width: 45vw;
    margin-top: 5vh;
    margin-left: 0px;
  }

  .subback {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 94vh;
    width: 94vw;
    z-index: 116;
    margin-top: 0vh;
  }

  .steamH {
    width: 30vw;
  }
}
