@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=Uncial+Antiqua&display=swap");

.navbar {
  background-color: #0a0705;
  color: #dcb67a;
  font-family: "Cinzel", serif;
  padding: 10px 20px;
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
}

.nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-left: 20px; /* Adjust as necessary */
}

.en-nav-links {
  display: flex;
  align-items: center;
}

.home-button img {
  height: 50px; /* Adjust size as needed */
  cursor: pointer;
}

.en-nav-link {
  color: #fff;
  font-size: 1vw;
  margin-right: 2vw;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  user-select: none; /* Prevent text selection */
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome and Safari */
  -ms-user-select: none; /* IE */
}

.en-nav-link.active,
.en-nav-link:hover {
  color: #dcb67a;
  border-bottom: 2px solid #dcb67a;
  padding-bottom: 8px;
  user-select: none; /* Prevent text selection */
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome and Safari */
  -ms-user-select: none; /* IE */
}

.subscribe-button {
  display: inline-block;
  background-color: rgb(82, 7, 7); /* Sets button background to red */
  border-color: rgb(212, 37, 6);
  border-width: 1px;
  margin-left: 1.5vw;
  color: #dcb67a;
  font-family: "Cinzel", serif;
  padding: 7px 18px; /* Keeps padding as is */
  font-size: 1vw; /* Increases font size for larger text */
  font-weight: bold; /* Makes the button text bold */
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  box-sizing: border-box; /* Ensures padding and border are included in the button's total size */
}

.subscribe-button:hover {
  background-color: darkred; /* Darkens the button on hover for a visual effect */
}

.link-button {
  display: inline-block;
  margin-right: 0.5vw;
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: cover;
  text-decoration: none;
}

.link-button:hover {
  filter: brightness(1.5) sepia(100%);
}

.social-links {
  display: flex;
  align-items: left;
  padding: 0px 32vw;

  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.local-button {
  display: inline-block;
  margin-right: 0.5vw;
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: cover;
  text-decoration: none;
}

.language-dropdown {
  background-color: #0a0705; /* Match navbar background */
  color: #dcb67a; /* Match navbar text color */
  border: none;
  font-size: 25px;
  text-align: right;
  padding: 5px 10px;
  width: 150px;
  margin-left: -52px;
  margin-right: 14px;
  border-radius: 5px; /* Optionally round the corners */
  font-family: "Lora", serif; /* Match navbar font */
  cursor: pointer;
}

.language-dropdown:hover {
  background-color: #1a1715; /* Slightly darker on hover for feedback */
}

@media (max-width: 2560px) {
  .social-links {
    display: flex;
    align-items: left;
    padding: 0px 61vw;
    position: absolute;
  }

  .subscribe-button {
    display: inline-block;
    background-color: rgb(82, 7, 7); /* Sets button background to red */
    border-color: rgb(212, 37, 6);
    border-width: 1px;
    min-width: 180px;
    margin-left: 1.5vw;
    height: 50px;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 7px 18px; /* Keeps padding as is */
    font-size: 1vw; /* Increases font size for larger text */
    font-weight: bold; /* Makes the button text bold */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    box-sizing: border-box; /* Ensures padding and border are included in the button's total size */

    direction: ltr; /* Default, but ensure it's not causing issues */
  }

  .link-button {
    display: inline-block;
    margin-right: 1vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1.5;
  }
  .enlocal-button {
    display: inline-block;
    margin-right: -0.8vw;
    margin-top: 5px;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1.5;
  }
  .eslocal-button {
    display: inline-block;
    margin-right: -0.5vw;
    margin-top: 5px;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1.5;
  }
  .frlocal-button {
    display: inline-block;
    margin-right: -0vw;
    margin-top: 5px;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1.5;
  }
  .delocal-button {
    display: inline-block;
    margin-right: -0.1vw;
    margin-top: 5px;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1.5;
  }
  .ptlocal-button {
    display: inline-block;
    margin-right: -0vw;
    margin-top: 5px;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1.5;
  }
  .zhlocal-button {
    display: inline-block;
    margin-right: 0.1vw;
    margin-top: 5px;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1.5;
  }
  .jalocal-button {
    display: inline-block;
    margin-right: 0.1vw;
    margin-top: 5px;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1.5;
  }
  .kolocal-button {
    display: inline-block;
    margin-right: 0.1vw;
    margin-top: 5px;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1.5;
  }

  .language-dropdown {
    background-color: #0a0705; /* Match navbar background */
    color: #dcb67a; /* Match navbar text color */
    border: none;
    font-size: 30px;
    text-align: right;
    padding: 5px 10px;
    width: 200px;
    margin-left: -52px;
    margin-right: 60px;
    border-radius: 5px; /* Optionally round the corners */
    font-family: "Lora", serif; /* Match navbar font */
    cursor: pointer;
  }

  .en-nav-links {
    display: flex;
    align-items: center;
    margin-left: 0vh;
  }
  .en-nav-link {
    color: #fff;
    font-size: 1.5vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .en-nav-link.active,
  .en-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.5vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
  }
  .es-nav-links {
    display: flex;
    align-items: center;
    margin-left: 0vh;
  }
  .es-nav-link {
    color: #fff;
    font-size: 1.5vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .es-nav-link.active,
  .es-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.5vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
  }
  .fr-nav-links {
    display: flex;
    align-items: center;
    margin-left: 0vh;
  }
  .fr-nav-link {
    color: #fff;
    font-size: 1.5vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .fr-nav-link.active,
  .fr-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.5vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
  }
  .de-nav-links {
    display: flex;
    align-items: center;
    margin-left: 0vh;
  }
  .de-nav-link {
    color: #fff;
    font-size: 1.5vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .de-nav-link.active,
  .de-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.5vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
  }
  .pt-nav-links {
    display: flex;
    align-items: center;
    margin-left: 0vh;
  }
  .pt-nav-link {
    color: #fff;
    font-size: 1.5vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .pt-nav-link.active,
  .pt-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.5vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
  }
  .zh-nav-links {
    display: flex;
    align-items: center;
    margin-left: 0vh;
  }
  .zh-nav-link {
    color: #fff;
    font-size: 1.5vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .zh-nav-link.active,
  .zh-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.5vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
  }
  .ja-nav-links {
    display: flex;
    align-items: center;
    margin-left: 0vh;
  }
  .ja-nav-link {
    color: #fff;
    font-size: 1.5vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .ja-nav-link.active,
  .ja-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.5vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
  }
  .ko-nav-links {
    display: flex;
    align-items: center;
    margin-left: 0vh;
  }
  .ko-nav-link {
    color: #fff;
    font-size: 1.5vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .ko-nav-link.active,
  .ko-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.5vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
  }
  .navbar {
    background-color: #0a0705;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 10px 20px;
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
  }
  .home-button {
    scale: 1.5;
    margin-right: -1vw;
    margin-left: 1vw;
    margin-top: 0.5vh;
  }
}

@media (max-width: 1920px) {
  .social-links {
    display: flex;
    align-items: left;
    padding: 0px 60vw;
    position: absolute;
  }

  .subscribe-button {
    display: inline-block;
    background-color: rgb(82, 7, 7); /* Sets button background to red */
    border-color: rgb(212, 37, 6);
    border-width: 1px;
    margin-left: 1.5vw;
    min-width: 150px;
    height: 40px;
    margin-top: 6px;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 7px 18px; /* Keeps padding as is */
    font-size: 1vw; /* Increases font size for larger text */
    font-weight: bold; /* Makes the button text bold */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    box-sizing: border-box; /* Ensures padding and border are included in the button's total size */
  }

  .link-button {
    display: inline-block;
    margin-right: 0.4vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }

  .enlocal-button {
    display: inline-block;
    margin-right: -0.5vw;
    margin-top: -0vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .eslocal-button {
    display: inline-block;
    margin-right: -0.5vw;
    margin-top: -0vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .frlocal-button {
    display: inline-block;
    margin-right: -0vw;
    margin-top: -0vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .delocal-button {
    display: inline-block;
    margin-right: -0.1vw;
    margin-top: -0vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .ptlocal-button {
    display: inline-block;
    margin-right: 0.8vw;
    margin-top: -0vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .zhlocal-button {
    display: inline-block;
    margin-right: 0.8vw;
    margin-top: -0vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .jalocal-button {
    display: inline-block;
    margin-right: 0.8vw;
    margin-top: -0vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .kolocal-button {
    display: inline-block;
    margin-right: 0.8vw;
    margin-top: -0vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .language-dropdown {
    background-color: #0a0705; /* Match navbar background */
    color: #dcb67a; /* Match navbar text color */
    border: none;
    font-size: 1.3vw;
    text-align: right;
    padding: 5px 10px;
    width: 165px;
    margin-left: -55px;
    margin-right: 14px;
    border-radius: 5px; /* Optionally round the corners */
    font-family: "Lora", serif; /* Match navbar font */
    cursor: pointer;
  }

  .en-nav-links {
    display: flex;
    align-items: center;
    margin-left: -1.5vw;
  }

  .en-nav-link {
    color: #fff;
    font-size: 1.2vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .en-nav-link.active,
  .en-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.2vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .es-nav-links {
    display: flex;
    align-items: center;
    margin-left: -1.5vw;
  }

  .es-nav-link {
    color: #fff;
    font-size: 1.2vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .es-nav-link.active,
  .es-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.2vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .fr-nav-links {
    display: flex;
    align-items: center;
    margin-left: -1.5vw;
  }

  .fr-nav-link {
    color: #fff;
    font-size: 1.2vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .fr-nav-link.active,
  .fr-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.2vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .en-nav-links {
    display: flex;
    align-items: center;
    margin-left: -1.5vw;
  }

  .de-nav-link {
    color: #fff;
    font-size: 1.2vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .de-nav-link.active,
  .de-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.2vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .pt-nav-links {
    display: flex;
    align-items: center;
    margin-left: -1.5vw;
  }

  .pt-nav-link {
    color: #fff;
    font-size: 1.2vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .pt-nav-link.active,
  .pt-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.2vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .navbar {
    background-color: #0a0705;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 10px 20px;
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
  }
  .home-button {
    scale: 1;
    margin-right: 0vw;
    margin-left: 0vw;
    margin-top: 0.5vh;
  }
}

@media (max-width: 1440px) {
  .social-links {
    display: flex;
    align-items: left;
    padding: 0px 53vw;
    position: absolute;
  }

  .subscribe-button {
    display: inline-block;
    background-color: rgb(82, 7, 7); /* Sets button background to red */
    border-color: rgb(212, 37, 6);
    border-width: 1px;
    margin-left: 1.5vw;
    margin-top: 6px;
    height: 38px;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 0px 18px; /* Keeps padding as is */
    font-size: 1.3vw; /* Increases font size for larger text */
    font-weight: bold; /* Makes the button text bold */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    box-sizing: border-box; /* Ensures padding and border are included in the button's total size */
  }

  .link-button {
    display: inline-block;
    margin-right: 0.5vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }

  .enlocal-button {
    display: inline-block;
    margin-right: -0.5vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .eslocal-button {
    display: inline-block;
    margin-right: -0.5vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .frlocal-button {
    display: inline-block;
    margin-right: -0vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .delocal-button {
    display: inline-block;
    margin-right: -0.1vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .ptlocal-button {
    display: inline-block;
    margin-right: 0.8vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .zhlocal-button {
    display: inline-block;
    margin-right: 0.8vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .jalocal-button {
    display: inline-block;
    margin-right: -0.8vw;
    margin-top: -0vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .kolocal-button {
    display: inline-block;
    margin-right: -0.8vw;
    margin-top: -0.1vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }

  .language-dropdown {
    background-color: #0a0705; /* Match navbar background */
    color: #dcb67a; /* Match navbar text color */
    border: none;
    font-size: 1.5vw;
    text-align: right;
    padding: 5px 10px;
    width: 150px;
    margin-left: -45px;
    margin-right: 14px;
    border-radius: 5px; /* Optionally round the corners */
    font-family: "Lora", serif; /* Match navbar font */
    cursor: pointer;
  }

  .en-nav-link {
    color: #fff;
    font-size: 1.5vw;
    margin-right: 1.5vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .en-nav-link.active,
  .en-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.5vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .es-nav-link {
    color: #fff;
    font-size: 1.4vw;
    margin-right: 1vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .es-nav-link.active,
  .es-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.5vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .fr-nav-link {
    color: #fff;
    font-size: 1.3vw;
    margin-right: 1vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .fr-nav-link.active,
  .fr-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.5vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .de-nav-link {
    color: #fff;
    font-size: 1.3vw;
    margin-right: 1vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .de-nav-link.active,
  .de-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.5vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .pt-nav-link {
    color: #fff;
    font-size: 1.4vw;
    margin-right: 1vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .pt-nav-link.active,
  .pt-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.4vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .navbar {
    background-color: #0a0705;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 2px 20px;
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
  }
  .home-button {
    scale: 1;
    margin-right: -1.5vw;
    margin-left: 0vw;
    margin-top: 0.5vh;
  }
}
@media (max-width: 1360px) {
  .social-links {
    display: flex;
    align-items: left;
    padding: 0px 50vw;
    position: absolute;
  }
  .subscribe-button {
    display: inline-block;
    background-color: rgb(82, 7, 7); /* Sets button background to red */
    border-color: rgb(212, 37, 6);
    border-width: 1px;
    margin-left: 1.5vw;
    margin-top: 8px;
    min-width: 150px;
    height: 35px;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 0px 18px; /* Keeps padding as is */
    font-size: 1vw; /* Increases font size for larger text */
    font-weight: bold; /* Makes the button text bold */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    box-sizing: border-box; /* Ensures padding and border are included in the button's total size */
  }

  .link-button {
    display: inline-block;
    margin-right: 0.2vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 0.9;
  }

  .enlocal-button {
    display: inline-block;
    margin-right: -0.5vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .eslocal-button {
    display: inline-block;
    margin-right: -0.5vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .frlocal-button {
    display: inline-block;
    margin-right: -0vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .delocal-button {
    display: inline-block;
    margin-right: -0.1vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .ptlocal-button {
    display: inline-block;
    margin-right: 0.8vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }
  .zhlocal-button {
    display: inline-block;
    margin-right: 0.8vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 1;
  }

  .language-dropdown {
    background-color: #0a0705; /* Match navbar background */
    color: #dcb67a; /* Match navbar text color */
    border: none;
    font-size: 1.5vw;
    text-align: right;
    padding: 5px 10px;
    width: 160px;
    margin-left: -55px;
    margin-right: 14px;
    border-radius: 5px; /* Optionally round the corners */
    font-family: "Lora", serif; /* Match navbar font */
    cursor: pointer;
  }

  .en-nav-link {
    color: #fff;
    font-size: 1.3vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .en-nav-link.active,
  .en-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.3vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .es-nav-link {
    color: #fff;
    font-size: 1.3vw;
    margin-right: 1vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .es-nav-link.active,
  .es-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.3vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .fr-nav-link {
    color: #fff;
    font-size: 1.3vw;
    margin-right: 1vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .fr-nav-link.active,
  .fr-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.3vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .de-nav-link {
    color: #fff;
    font-size: 1.3vw;
    margin-right: 1vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .de-nav-link.active,
  .de-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.3vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .pt-nav-link {
    color: #fff;
    font-size: 1.3vw;
    margin-right: 2vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .pt-nav-link.active,
  .pt-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 1.3vw;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .navbar {
    background-color: #0a0705;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 2px 20px;
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
  }

  .home-button {
    scale: 1;
    margin-right: -1.5vw;
    margin-left: 0vw;
    margin-top: 0.5vh;
  }
}
@media (max-width: 1024px) {
  .social-links {
    display: flex;
    align-items: left;
    padding: 0px 50vw;
    position: absolute;
  }
  .language-dropdown {
    background-color: #0a0705; /* Match navbar background */
    color: #dcb67a; /* Match navbar text color */
    border: none;
    font-size: 14px;
    text-align: right;
    padding: 5px 10px;
    width: 100px;
    margin-left: -42px;
    margin-right: 14px;
    border-radius: 5px; /* Optionally round the corners */
    font-family: "Lora", serif; /* Match navbar font */
    cursor: pointer;
  }
  .subscribe-button {
    display: inline-block;
    background-color: rgb(82, 7, 7); /* Sets button background to red */
    border-color: rgb(212, 37, 6);
    border-width: 1px;
    margin-left: 1.5vw;
    margin-top: 8px;
    min-width: 90px;
    height: 35px;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 0px 18px; /* Keeps padding as is */
    font-size: 1vw; /* Increases font size for larger text */
    font-weight: bold; /* Makes the button text bold */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    box-sizing: border-box; /* Ensures padding and border are included in the button's total size */
  }

  .enlocal-button {
    display: inline-block;
    margin-right: 0.3vw;
    margin-top: -0vh;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 0.7;
  }
  .eslocal-button {
    display: inline-block;
    margin-right: 0.3vw;
    margin-top: -0vh;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 0.7;
  }
  .frlocal-button {
    display: inline-block;
    margin-right: 0.3vw;
    margin-top: -0vh;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 0.7;
  }
  .delocal-button {
    display: inline-block;
    margin-right: 0.3vw;
    margin-top: -0vh;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 0.7;
  }
  .ptlocal-button {
    display: inline-block;
    margin-right: 1.2vw;
    margin-top: -0vh;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 0.7;
  }
  .zhlocal-button {
    display: inline-block;
    margin-right: 1.5vw;
    margin-top: -0vh;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 0.7;
  }
  .jalocal-button {
    display: inline-block;
    margin-right: 0.2vw;
    margin-top: -0vh;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 0.7;
  }
  .kolocal-button {
    display: inline-block;
    margin-right: 0.2vw;
    margin-top: -0vh;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 0.7;
  }

  .language-dropdown:hover {
    background-color: #1a1715; /* Slightly darker on hover for feedback */
  }
  .subscribe-button {
    display: inline-block;
    background-color: rgb(82, 7, 7); /* Sets button background to red */
    border-color: rgb(212, 37, 6);
    border-width: 1px;
    margin-left: 1.5vw;
    margin-top: 11px;
    height: 28px;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 0px 18px; /* Keeps padding as is */
    font-size: 1vw; /* Increases font size for larger text */
    font-weight: bold; /* Makes the button text bold */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    box-sizing: border-box; /* Ensures padding and border are included in the button's total size */
  }

  .link-button {
    display: inline-block;
    margin-right: -0.5vw;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    text-decoration: none;
    scale: 0.8;
  }

  .en-nav-link {
    color: #fff;
    font-size: 15px;
    margin-right: 1vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .en-nav-link.active,
  .en-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 15px;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .es-nav-link {
    color: #fff;
    font-size: 14px;
    margin-right: 0vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .es-nav-link.active,
  .es-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 15px;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .fr-nav-link {
    color: #fff;
    font-size: 13.5px;
    margin-right: 0vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .fr-nav-link.active,
  .fr-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 15px;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .de-nav-link {
    color: #fff;
    font-size: 14px;
    margin-right: 0vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .de-nav-link.active,
  .de-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 15px;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .pt-nav-link {
    color: #fff;
    font-size: 14px;
    margin-right: 0vw;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }

  .pt-nav-link.active,
  .pt-nav-link:hover {
    color: #dcb67a;
    border-bottom: 2px solid #dcb67a;
    font-size: 14px;
    padding-bottom: 8px;
    user-select: none; /* Prevent text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome and Safari */
    -ms-user-select: none; /* IE */
    z-index: 10000;
  }
  .navbar {
    background-color: #0a0705;
    color: #dcb67a;
    font-family: "Cinzel", serif;
    padding: 0px 20px;
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
  }
  .home-button {
    scale: 0.9;
    margin-right: -1.5vw;
    margin-left: 0vw;
    margin-top: 0.5vh;
  }
}
@media (max-width: 720px) {
  .social-links {
    display: flex;
    align-items: left;
    padding: 0px 8vw;
  }
}
